.background {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
}

.modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  gap: 14px;
  max-width: 420px;
  border-radius: 30px;
  padding: 36px;
}

.modal p {
  font-size: 44px;
  text-align: center;
  color: #3048ea;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fields input,
.fields textarea {
  border-radius: 12px;
  padding: 10px;
  border: 1px solid #d7d7d7;
  resize: none;
  outline: none;
}

.fields textarea {
  min-height: 140px;
}

.modal button {
  border-radius: 42px;
  background-color: #3048ea;
  color: white;
  font-size: 20px;
  border: none;
  padding: 20px 40px;
  cursor: pointer;
}

.loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
