a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

.styles_header__B_XVD {
  display: flex;
  justify-content: space-between;
  border-radius: 40px;
  background-color: white;
  align-items: center;
  padding: 24px;
  margin: 24px;
  font-size: 14px;
  position: relative;
}

.styles_list__Ni8PG {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  gap: 14px;
}

.styles_list__Ni8PG li {
  margin: 0;
  padding: 0;
}

.styles_list__Ni8PG > li > a {
  color: #14151f;
  font-weight: 600;
}

.styles_navigate__49ly5 {
  display: flex;
  align-items: center;
  gap: 14px;
}

.styles_navigate__49ly5 > a {
  font-family: inherit;
  border-radius: 16px;
  padding: 8px 16px;
  background-color: #3048ea;
  color: white;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.styles_footer__X4aWR {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  height: 410px;
  background-image: url(/_next/static/media/ourTeam.7fe90795.png);
  background-size: 100% 750px;
  background-repeat: no-repeat;
}

.styles_ad__19xrN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 48px);
  background-color: #8230ea;
  padding: 24px;
}

.styles_ad__19xrN p {
  font-size: 20px;
  font-weight: 400;
  color: white;
}

.styles_ad__19xrN a {
  font-family: inherit;
  color: #8230ea;
  border: none;
  background-color: white;
  padding: 16px 32px;
  border-radius: 42px;
}

.styles_footerNav__0ERqV {
  width: calc(100% - 48px);
  margin: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.styles_copyright__d9Ke6 {
  border-top: 1px solid #14151f1a;
  width: calc(100% - 48px);
  margin: 24px;
  margin-top: 0;
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #14151f66;
  font-size: 14px;
}

.styles_footerContent__Erkbc {
  vertical-align: bottom;
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: 0;
}

.styles_motiondiv__ZE4oT {
  position: absolute;
  right: 0;
  left: 0;
  padding: 1.25rem;
  padding-top: 0;
  border-bottom-width: 1px;
  top: 100%;
  z-index: 1;
  background-color: white;
  border-radius: 40px;
}

.styles_button__cjdnJ {
  display: inline-block;
  font-family: inherit;
  width: calc(100% - 32px);
  border-radius: 16px;
  padding: 10px 16px;
  background-color: #3048ea;
  color: white;
  font-weight: 600;
  font-size: 18px;
  border: none;
  cursor: pointer;
}

.styles_burgerList__yOPK6 {
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.styles_motionli__VL2FN {
  border-radius: 0.75rem;
  width: 100%;
  padding: 0.08rem;
}

.styles_motionli__VL2FN li {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  gap: 14px;
}

.styles_motionli__VL2FN a {
  color: #14151f;
  font-weight: 600;
}

.styles_hamburgerLink__hbKpC {
  display: flex;
  padding: 1.25rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.75rem;
  width: 100%;
}

.styles_hamburgerTitle__SWZdW {
  display: flex;
  gap: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.styles_burderMenu__s99_1 {
  display: none;
}

@media (max-width: 1024px) {
  .styles_header__B_XVD .styles_navigate__49ly5 {
    display: none;
  }

  .styles_burderMenu__s99_1 {
    display: block;
  }

  .styles_footerNav__0ERqV {
    flex-direction: column;
    gap: 28px;
    align-items: center;
    justify-content: center;
  }

  .styles_footerNav__0ERqV .styles_list__Ni8PG {
    flex-direction: row;
    font-size: 12px;
    gap: 4px;
  }

  .styles_ad__19xrN a {
    font-size: 12px;
  }
}

.styles_background__To58r {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.styles_modal__ZQMq3 {
  display: flex;
  flex-direction: column;
  background-color: white;
  gap: 14px;
  max-width: 420px;
  border-radius: 30px;
  padding: 36px;
}

.styles_modal__ZQMq3 p {
  font-size: 44px;
  text-align: center;
  color: #3048ea;
}

.styles_fields__9LdaW {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.styles_fields__9LdaW input,
.styles_fields__9LdaW textarea {
  border-radius: 12px;
  padding: 10px;
  border: 1px solid #d7d7d7;
  resize: none;
  outline: none;
}

.styles_fields__9LdaW textarea {
  min-height: 140px;
}

.styles_modal__ZQMq3 button {
  border-radius: 42px;
  background-color: #3048ea;
  color: white;
  font-size: 20px;
  border: none;
  padding: 20px 40px;
  cursor: pointer;
}

.styles_loader__BXa0S {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  animation: styles_rotate__e4LZq 1s linear infinite;
}
.styles_loader__BXa0S::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: styles_prixClipFix__wzVZ4 2s linear infinite;
}

@keyframes styles_rotate__e4LZq {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes styles_prixClipFix__wzVZ4 {
  0% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
            clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@font-face {
font-family: '__stolzl_037fd2';
src: url(/_next/static/media/539fc6aba550d3e3-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__stolzl_037fd2';
src: url(/_next/static/media/28692e6307c38c6d-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__stolzl_Fallback_037fd2';src: local("Arial");ascent-override: 80.39%;descent-override: 21.16%;line-gap-override: 0.00%;size-adjust: 118.17%
}.__className_037fd2 {font-family: '__stolzl_037fd2', '__stolzl_Fallback_037fd2'
}.__variable_037fd2 {--font-stolzl: '__stolzl_037fd2', '__stolzl_Fallback_037fd2'
}

@font-face {
font-family: '__sfPro_845d95';
src: url(/_next/static/media/cea3f292d0af233b-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__sfPro_845d95';
src: url(/_next/static/media/e59a42268a5beb0b-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
}@font-face {font-family: '__sfPro_Fallback_845d95';src: local("Arial");ascent-override: 88.96%;descent-override: 22.54%;line-gap-override: 0.00%;size-adjust: 107.03%
}.__className_845d95 {font-family: '__sfPro_845d95', '__sfPro_Fallback_845d95'
}.__variable_845d95 {--font-sf-pro: '__sfPro_845d95', '__sfPro_Fallback_845d95'
}

